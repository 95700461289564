import commonApi from '../_EXT_API'
import {singleProxy} from '@/global/js/exportEnter'

class login extends commonApi{
    constructor(opt){
        opt = opt || {}
        let localKey = 'task'
        let name = 'task'
        opt = {localKey, name}
        super(opt)
    }
    async coursePower(opt){
        opt = opt || {}
        let {course_id,user_code} = opt
        let options = {
            method: "get",
            user_code:user_code,
            data:{
                course_id:course_id,
            }
        }
        return await this.requestAction('coursePower', options)
    }
    
    async courseProgress(opt){
        opt = opt || {}
        let {course_id} = opt
        let options = {
            method: "get",
            course_id,
            data:{
            }
        }
        return this.requestAction('courseProgress', options)
    }
    async courseChapter(opt){
        opt = opt || {}
        let {user_code,course_id} = opt
        let options = {
            method: "get",
            user_code:user_code, 
            data:{
                course_id:course_id,
            }
        }
        return await this.requestAction('courseChapter', options)
    }
    
    async courseList(opt) {
        let {chapter_id} = opt
        let options = {
            method: "get",
            chapter_id:chapter_id,
            data:{
            }
        }
        return await this.requestAction('courseList', options)
    }
    async courseListCo(opt){
        let {page = 0, size = 15, user_code} = opt || {}
        user_code = user_code || this._appData.getItem('usercode', 'app')
        let options = {
            method: "get",
            user_code,
            ...opt,
            data:{
                page, size
            }
        }
        let res = await this.requestAction('courseListCo', options)
        // return res
        if(!res) return res
        let {content} = res
        return content
        // return list
        // let schoolId = this.PARAMS.school_id
        // let rd = []
        // for(let i in list){
        //     if(list[i].schoolId == )
        // }
    }
    async courseListCov(opt){
        let {page = 0, size = 15, user_code} = opt || {}
        user_code = user_code || this._appData.getItem('usercode', 'app')
        let options = {
            method: "get",
            user_code,
            ...opt,
            data:{
                page, size
            }
        }
        let res = await this.requestAction('courseListCov', options)
        if(!res) return res
        let {courseList: data} = res
        let {disabled_course_id: disabledId} = this.PARAMS
        let list = []
        for(let i in data){
            let {id} = data[i]
            if(disabledId[id]) continue
            list.push(data[i])
        }
        return list
        // let schoolId = this.PARAMS.school_id
        // let rd = []
        // for(let i in list){
        //     if(list[i].schoolId == )
        // }
    }


    async uploadCourse(opt) {
        let {user_code,exercise_id,user_answer,correct} = opt
        let options = {
            method: "post",
            user_code:user_code,
            data:{
                exerciseId:exercise_id, 
                userAnswer:user_answer,
                correct:correct,
            }
        }
        return this.requestAction('uploadCourse', options)
    }
    
    async courseSituation(opt) {
        let {user_code,chapter_id} = opt
        let options = {
            method: "get",
            user_code:user_code,
            data:{
                chapter_id:chapter_id,
            }
        }
        return this.requestAction('courseSituation', options)
    }
    



    //权限验证
    async courseAuth(opt){
        opt = opt || {}
        let {course_id,user_code} = opt
        let options = {
            method: "get",
            user_code:user_code,
            data:{
                course_id:course_id,
            }
        }
        return await this.requestAction('courseAuth', options)
    }
    //章节列表
    async chapterList(opt){
        opt = opt || {}
        let {user_code,course_id} = opt
        user_code = user_code || this._appData.getItem('usercode', 'app')
        let options = {
            method: "get",
            user_code, 
            data:{
                ...opt,
                course_id,
            }
        }
        return await this.requestAction('chapterList', options)
    }
    //作业列表
    async exerciseList(opt){
        let {chapter_id} = opt
        let options = {
            method: "get",
            chapter_id,
            data:{
                ...opt
            }
        }
        let res = await this.requestAction('exerciseList', options)
        if(!res) return res
        return this._common.mindExerciseHandler(res)
    }
    //答题上传
    async resultUpload(opt){
        let {exerciseId, userAnswer, correct, answerDuration} = opt
        let options = {
            method: "post",
            ...opt,
            data:{
                exerciseId, 
                userAnswer,
                correct,
                answerDuration
            }
        }
        let res = await this.requestAction('exerciseUpload', options)
        if(res) this.balanceCheck()
        return res
    }
    //章节做题状态
    async chapterExStatus(opt) {
        let {chapter_id} = opt
        let options = {
            method: "get",
            ...opt,
            data:{
                ...opt,
                chapter_id,
            }
        }
        return this.requestAction('chapterExStatus', options)
    }

    // 专项题库获取章节目录列表
    async subjectChapterList(opt) {
        let {course_id} = opt
        // school_id = school_id || this.PARAMS.school_id
        let options = {
            method: "get",
            // school_id,
            data:{
                course_id
            }
        }
        return await this.requestAction('subjectChapterList', options)
    }
    // 专项题库获取二级目录列表
    async subjectSectionList(opt) {
        let {user_code} = opt
        user_code = user_code || this._appData.getItem('usercode', 'app')
        opt.user_code = user_code
        let options = {
            method: "get",
            data:{
                ...opt
            }
        }
        return await this.requestAction('subjectSectionList', options)
    }
    // 专项题库获取三级目录列表
    async subjectExerciseList(opt) {
        // school_id = school_id || this.PARAMS.school_id
        let {user_code} = opt
        user_code = user_code || this._appData.getItem('usercode', 'app')
        opt.user_code = user_code
        let options = {
            method: "get",
            // school_id,
            data:{
                ...opt
            }
        }
        let res = await this.requestAction('subjectExerciseList', options)
        if(!res) return res
        return this._common.mindExerciseHandler(res)
    }
    // 专项题库获取exercise列表
    async subjectExercise(opt) {
        // let {school_id} = opt
        // school_id = school_id || this.PARAMS.school_id
        let options = {
            method: "get",
            data:{
                ...opt
            }
        }
        let res = await this.requestAction('subjectExercise', options)
        if(!res) return res
        return this._common.mindExerciseHandler(res)

    }
    // 专项题库答题上传
    async subAnswerUpload(opt){
        let {exerciseId, userAnswer, correct, usercode, school_id, answerDuration} = opt
        school_id = school_id || this.PARAMS.school_id
        let options = {
            method: "post",
            user_code: usercode,
            data:{
                exerciseId, 
                userAnswer,
                correct,
                schoolId: school_id,
                answerDuration
            }
        }
        let res = await this.requestAction('subAnswerUpload', options)
        if(res) this.balanceCheck()
        return res
    }
    // 上传sgf生成图片 --test
    async createImageBoardFile(opt) {
        let options = {
            method: "post",
            data:{
                ...opt
            }
        }
        return await this.requestAction('createImageBoardFile', options)
    }
    // 更新列表棋谱图片 --test
    async updateImage(opt) {
        let options = {
            method: "post",
            data:{
                ...opt
            }
        }
        return await this.requestAction('updateImage', options)
    }

    async sectionInfo(opt){
        opt = opt || {}
        let {section_id} = opt
        if(!section_id) return
        let options = {
            method: "get",
            ...opt,
            section_id,
            data:{
            }
        }
        return this.requestAction('sectionInfo', options)
    }

    async userDuration(opt){
        opt = opt || {}
        let {user_code = this._appData.getItem('usercode', 'app'), type} = opt
        if(!user_code || !type) return
        let api = `${type}Duration`
        let options = {
            method: "get",
            ...opt,
            user_code,
            data:{
                ...opt
            }
        }
        return this.requestAction(api, options)
    }
    
    async userExerciseList(opt) {
        opt = opt || {}
        let {user_code} = opt || {}
        user_code = user_code || this._appData.getItem('usercode', 'app')
        opt.user_code = user_code
        let options = {
            method: "get",
            ...opt,
            data: {
                ...opt
            }
        }
        return this.requestAction('userExerciseList', options)
    }

    async userExpandExerciseList(opt){
        opt = opt || {}
        let {chapter_id, user_code} = opt || {}
        user_code = user_code || this._appData.getItem('usercode', 'app')
        opt.user_code = user_code
        if(!chapter_id) return
        let options = {
            method: "get",
            ...opt,
            chapter_id,
            data: {
                ...opt
            }
        }
        let res = await this.requestAction('userExpandExerciseList', options)
        return this._common.mindExerciseHandler(res)
    }

    // 专项题库状态
    async subjectStatus(opt){
        opt = opt || {}
        let {user_code} = opt
        user_code = user_code || this._appData.getItem('usercode', 'app')
        if(!user_code) return
        let options = {
            method: "get",
            ...opt,
            user_code,
            data: {
            }
        }
        let res = await this.requestAction('subjectStatus', options)
        return res
    }
}

export default singleProxy(login)