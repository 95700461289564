<template>
    <div class="mind-trial-bcg bold scale-button" @click="activateFun">
        <div class="progress-bar-box">
            <!-- 进度条设置的宽度占比为90 -->
            <div class="progress-bar" v-if="remainingSecond" :style="{ transform: `translateX(-${ remainingSecond / ( 168 * 3600) * 100 > 100 ? 0 : 90 - remainingSecond / ( 168 * 3600) * 90 }%)` }"></div>
        </div>
        <div class="clock">
            <div class="t4"></div>
        </div>
        <div class="title">
            <div class="txt-a"></div>
            <div class="txt-b"></div>
            <div class="txt-c"></div>
        </div>
        <div class="time-box">
            剩余{{ remainingTime.hours || 0 }}小时{{ remainingTime.minutes || 0 }}分钟{{ remainingTime.seconds || 0 }}秒
        </div>
    </div>
</template>
<script>
export default {
    name: '',
    props: {
    },
    data() {
        return {
            timer: null,
            remainingTime: {
                hours: null,
                minutes: null,
                seconds: null,
            },
            remainingSecond: null,
        };
    },
    methods: {
        updateRemainingTime(remaining) {
            let seconds = remaining % 60
            let minutes = remaining / 60

            this.remainingTime.hours = Math.floor(minutes / 60)
            this.remainingTime.minutes = Math.floor(minutes % 60)
            this.remainingTime.seconds = Math.floor(seconds)
        },
        setTime(){
            // 每秒钟更新一次倒计时
            this.remainingSecond = this.activateRemaining || 0
            if(!this.remainingSecond) return
            this.updateRemainingTime(this.remainingSecond)
            this.clearTimer()
            this.timer = setInterval(() => {
                let remaining = this.remainingSecond -= 1;
                if (remaining < 0) {
                    this.clearTimer()
                    return this.activateMember()
                }
                // 更新剩余时间
                this.updateRemainingTime(remaining)
            }, 1000); // 每秒钟执行一次
        },
        clearTimer(){
            clearInterval(this.timer);
        },
        activateFun(){
            this._common.activateMember()
        },
    },
    created() { },
    components: {
    },
    computed: {
        timeFlag(){
            let {remainingTime: {hours, minutes, seconds}} = this
            if(hours < 0 && minutes < 0 && seconds < 0) return false
            return true
        },
        activateRemaining(){
            let {app: {activateRemaining = null}} = this.$store.state
            return activateRemaining
        },
    },
    watch: {
        activateRemaining: {
            handler(newVal, oldVal) {
                if(newVal) this.setTime()
            },
            deep: true,
            immediate: true,
        }
    },
    mounted() { 
    },
    beforeCreate() { }, //生命周期 - 创建之前
    beforeMount() { }, //生命周期 - 挂载之前
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { 
        this.clearTimer()
    }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less' scoped>
    .mind-trial-bcg{
        .background-img-max(url('@{assetsUrlMind}/img/home/t1.png'));
        width: 108px;
        height: 20px;
        position: absolute;
        top: 11px;
        left: -195px;
        .time-box{
            // width: ;
            font-size: 7px;
            color: #A90A00;
            position: absolute;
            top: -11px;
            left: 13px;
            white-space: nowrap;
        }
        .progress-bar-box{
            width: 107px;
            height: 17px;
            position: absolute;
            top: 2px;
            left: 0;
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
            overflow: hidden;
        }
        .progress-bar{
            .background-img-max(url('@{assetsUrlMind}/img/home/t2.png'));
            width: 100%;
            height: 16.5px;
            position: absolute;
            top: 0px;
            left: 0;
            border-radius: 15px;
        }
        .clock{
            .background-img-max(url('@{assetsUrlMind}/img/home/t3.png'));
            position: absolute;
            width: 31px;
            height: 35px;
            left: -19px;
            bottom: -4px;
            margin: auto;
            z-index: 1;
            .t4{
                .background-img-max(url('@{assetsUrlMind}/img/home/t4.png'));
                width: 9px;
                height: 9px;
                position: absolute;
                top: 14px;
                left: 12.5px;
            }
        }
        .title{
            .background-img-max(url('@{assetsUrlMind}/img/home/t5.png'));
            width: 20px;
            height: 42px;
            position: absolute;
            left: -38px;
            bottom: -9px;
            color: #E25E00;
            .txt-a{
                .background-img-max(url('@{assetsUrlMind}/img/home/t5_1.png'));
                font-size: 12px;
                position: absolute;
                top: 1px;
                left: 7px;
                width: 12px;
                height: 12px;
            }
            .txt-b{
                .background-img-max(url('@{assetsUrlMind}/img/home/t5_2.png'));
                font-size: 13px;
                position: absolute;
                top: 15.5px;
                left: 0.5px;
                width: 12px;
                height: 13px;
            }
            .txt-c{
                .background-img-max(url('@{assetsUrlMind}/img/home/t5_3.png'));
                font-size: 12px;
                position: absolute;
                left: 8px;
                bottom: 1px;
                width: 11px;
                height: 12px;
            }
        }
    }
</style>