<!--  -->
<template>
    <div class='page-index'>
        <div class="bcg-box">
            <bcg>
                <template v-slot:items>
                    <div class="index-module-item" 
                    @mousedown="$event.currentTarget.classList.add(!v.disabled ? 'button-scale-down' : 'd')"
                    @touchstart="$event.currentTarget.classList.add(!v.disabled ? 'button-scale-down' : 'd')"
                    @touchleave="$event.currentTarget.classList.remove(!v.disabled ? 'button-scale-down' : 'd')"
                    @mouseleave="$event.currentTarget.classList.remove(!v.disabled ? 'button-scale-down' : 'd')"
                    @touchend="$event.currentTarget.classList.remove(!v.disabled ? 'button-scale-down' : 'd')"
                    @mouseup="$event.currentTarget.classList.remove(!v.disabled ? 'button-scale-down' : 'd')"
                    v-for="(v, i) in moduleList" :key="i" :class="[v.class, {'disabled': v.disabled}]" @click="gotoPage(v)"></div>
                    <div class="avatar-box">
                        <avatarPet pageName="Home" :gif="true" :avatarPointer="true"
                        type="concise"
                        @click="_routeHand.goto({
                            name: 'user',
                            type: 'push',
                        })"></avatarPet>
                    </div>
                </template>
            </bcg>
            <animationCloud></animationCloud>
        </div>
        <div class="user-box">
            <div class="user-box-fx">
                <userInfo></userInfo>
                <div class="g71 flex-center cursor-pointer scale-button" @click="taskOpen">
                    <span class="text-small text bold">任务中心</span>
                    <div class="task-center-animation">
                        <!-- <normalAnimation :urls.sync="urls"></normalAnimation> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="test-lesson" @click="testLesson">
            <span class="text-large">测试课程</span>
        </div> -->
    </div>
</template>

<script>
    import userInfo from '../../components/userInfo.vue'
    import bcg from './bcg.vue'
    import animationCloud from './animation.vue'
    import apiClass from '@/API/page/app'
    // import normalAnimation from '@/components/animation.vue'


    export default {
        name: '',
        props: {
            appStyle: Object,
            htmlFontSize: [Number, String]
        },
        data() {
            return {
                api: new apiClass(),
                urls: [],
                itemLine: [
                    {title: this.pageText.t1, routeName: 'subject', bcg: 'img/home/h_4.png', textBcg: '#FFB332', disabled: false, extendBcg: 's1'},
                    {title: this.pageText.t2, routeName: 'game', bcg: 'img/home/h_5.png', textBcg: '#0071FF'},
                ],
                itemBlock: [
                    {title: this.pageText.t3, routeName: 'task', bcg: 'img/home/h_6.png'},
                    {title: this.pageText.t4, routeName: 'mistakes', bcg: 'img/home/h_7.png'},
                ],
                headMenu: [
                    // {title: this.pageText.t5, icon: 'img/home/h_1.png'},
                    // {title: this.pageText.t6, icon: 'img/home/h_2.png'},
                    {title: this.pageText.t7, icon: 'img/home/h_3.png'},
                ],
                debugData: null,
                debugActive: true,
                moduleList: [
                    {class: 'g66', routeName: 'cabin'},
                    {class: 'g67', routeName: 'task'},
                    {class: 'g68', routeName: 'subject'},
                    {class: 'g69', routeName: 'game'},
                ],
                taskState: false,
                backForWindow: {
                    'taskCenter': 'taskCenterOpen'
                },
                query: this.$route.query,
            };
        },
        methods: {
            gotoPage(val){
                val = val || {}
                let {routeName, disabled} = val
                if(disabled) return
                this._voiceHand.normalPlay('index button')
                this._routeHand.goto({
                    name: routeName,
                    type: 'push'
                })
            },
            sizeDebug(){
                if(!this.debugOn()) return
                let html = document.getElementById('golaxy-gaotu-app-html')
                let {userAgent = ''} = navigator || {}
                let debugData = {}
                debugData.screenWidth = window.screen.width
                debugData.screenHeight = window.screen.height
                debugData.innerWidth = window.innerWidth
                debugData.innerHeight = window.innerHeight
                debugData.clientWidth = document.documentElement.clientWidth
                debugData.clientHeight = document.documentElement.clientHeight
                debugData.htmlWidth = html.offsetWidth
                debugData.htmlHeight = html.offsetHeight
                debugData.userAgent = userAgent
                debugData.gaotuJSBridge = window.gaotuJSBridge
                debugData.bodyClientWidth = document.body.clientWidth
                debugData.bodyClientHeight = document.body.clientHeight
                debugData.scrollTop = document.body.scrollTop 
                debugData.scrollLeft = document.body.scrollLeft 
                debugData.htmlFontSize = this.htmlFontSize
                

                this.debugData = {...this.appStyle, ...debugData}
            },
            debugOn(){
                let {app: {usercode} = {}} = this.$store.state
                return usercode == '66100628' || usercode == '69837100'
            },
            gotoModule(val){
                let {disabled} = val || {}
                if(disabled) return
                this._voiceHand.normalPlay('index button')
            },
            taskOpen(){
                this._common.taskCenter()
            },
            pageInit(){
                this.imgInit()
                this.api.userAccount()
            },
            imgInit(){
                let num = 101
                for(let i = 0; i < num; i++){
                    this.urls.push(`${this.PARAMS.assetsUrlV2_0}/animation/com/task_center/${i}.png`)
                }
            },
            queryBackHand(){
                let {query} = this
                query = query || {}
                let f = false
                for(let i in query){
                    let fun = this.backForWindow[i]
                    if(this[fun]){
                        f = this[fun](query[i])
                    }
                }
                return f
            },
            taskCenterOpen(routeName){
                if(!this._common.taskCenter) return
                let {name} = this.$route
                // if(routeName != name) return
                this._common.taskCenter()
                // this._routeHand.clearQuery('taskCenter')
                this.query = {}
                return true
            },
            eventSet(){
                this._eventBus.$on('globalComponentsMounted', this.globalComponentsMounted)
            },
            eventOff(){
                this._eventBus.$off('globalComponentsMounted')
            },
            globalComponentsMounted(){
                this.queryBackHand()
            },
            testLesson(){
                window.location = 'superclass://jump?category=native&action_type=live_v2&clazzNumber=9018648438774976&clazzLessonNumber=9018648439102656'
            }
        },
        created() {
            this.eventSet()
            this.pageInit()
        },
        components: {
            bcg,
            animationCloud,
            userInfo,
            // normalAnimation,
        },
        computed: {},
        watch: {
            // query: {
            //     handler(){
            //         this.queryBackHand()
            //     },
            //     deep: true,
            //     immediate: true
            // },
            $route: {
                handler(){
                    this.query = this.$route.query
                },
                deep: true,
                immediate: true
            }
        },
        mounted() {
            // this.queryBackHand()
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {
        }, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .page-index{
        // height: 100%;
        width: @designWidth;
        height: @designHeight;
        position: relative;
        .i-item.a{
            display: flex;
            // padding-left: 10px;
            white-space: nowrap;
            height: 58px;
            align-items: center;
            width: 571px;
            // position: absolute;
            top: 20px;
            padding-top: 20px;
            left: @app-padding-side;
            padding-left: 12px;
            width: calc(189px * 2 + 20px + 153px + 20px);
            .user-ot{
                width: 100%;
                text-align: right;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .ot-item{
                    text-align: center;
                    .i-it.text{
                        height: 10px;
                        line-height: 10px;
                    }
                    .i-it.img{
                        height: 24px;
                        width: 24px;
                        margin: 0px auto 4px;
                        img{
                            height: 100%;
                            width: auto;
                        }
                    } 
                }
                .ot-item+ .ot-item{
                    margin-left: 27px;
                }
            }
        }
        .i-item.b{
            height: calc(100% - 58px);
            // padding-top: calc(72px - @app-padding-head);
            .ib-body{
                height: 100%;
                display: flex;
                align-items: center;
            }
            .ib-item{
                display: flex;
                .i-l-item{
                    width: 100%;
                    .body{
                        height: 100%;
                        position: relative;
                        .extend-bcg{
                            position: absolute;
                        }
                        .extend-bcg.s1{
                            top: 183px;
                            right: 127px;
                            width: 110px;
                            height: 120px;
                            background: url('@{assetsUrl}/img/home/h8.png');
                            background-size: 100% 100%;
                        }
                    }
                }
            }
            .ib-item.line{
                margin-right: 20px;
                flex-flow: nowrap;
                .i-l-item+ .i-l-item{
                    margin-left: 20px;
                }
                .i-l-item{
                    width: 189px;
                    height: 268px;
                    .body{
                        padding-top: 16px;
                    }
                    .it-text{
                        width: 132px;
                        height: 38px;
                        line-height: 38px;
                        text-align: center;
                        border-radius: 38px;
                        margin: 0px auto;
                    }
                }

            }
            .ib-item.block{
                flex-flow: wrap;
                align-content: space-between;
                width: 153px;
                .i-l-item+ .i-l-item{
                    margin-top: 20px;
                }
                .i-l-item{
                    height: 124px;
                    .it-text{
                        padding: 12px 12px;
                    }
                }
            }
        }
        .debug-data{
            position: fixed;
            top: 10px;
            left: 60px;
            z-index: 999;
            
            padding-right: 20px;
            .data-body{
                display: flex;
                flex-wrap: wrap;
                background: #fff;
                color: #333;
            }
            .data-item+ .data-item{
                margin-left: 10px;
            }
            .de-close.open{
                color: #000;
            }
            .de-close.close{
                color: #f83838;
            }
            .de-close{
                position: absolute;
                right: 5px;
                top: 0px;
                z-index: 2;
            }
        }


        .user-box{
            display: flex;
            justify-content: flex-end;
            position: fixed;
            padding-right: 19px;
            width: @designWidth;
            left: 50%;
            transform: translateX(-50%);
            top: 4px;
            z-index: 3;
        }
        .g71{
            .text{
                // width: 58px;
                // height: 15px;
                // .background-img-max(url('@{assetsUrlV2_0}/index/g72.png'));
            }
        }
        .g71{
            width: 82px;
            height: 37px;
            margin-top: 9px;
            float: right;
            .background-img-max(url('@{assetsUrlV2_0}/index/g71.png'));
            .task-center-animation{
                position: absolute;
                width: 82px;
                height: 37px;
                transform: scale(1.55);
                margin-top: -2px;
                .background-img-max(url('@{assetsUrlV2_0}/animation/com/task_center/task.gif'));
                background-size: 97% 100%;
                .com-global-animation-image{
                    top: -33%;
                    left: -25%;
                    width: 148%;
                    height: 100%;
                }
            }
        }
        .avatar-box{
            position: absolute;
            bottom: 18px;
            left: 360px;
            .com-global-user-avatar{
                .user-avatar{
                    img{
                        height: 130px;
                        width: auto;
                    }
                }
            }
            .com-global-avatar-pet .com-global-pet-box{
                margin: 0px;
            }
            .com-user-pet .pet-body-content{
                width: auto;
            }
            .com-global-pet-animation{
                img{
                    width: 50px;
                }
            }
        }
        .text-box{
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            z-index: 10;
            background: rgba(0, 0, 0, 0.5);
        }
        .test-lesson{
            z-index: 3;
            position: absolute;
            color: #333;
        }
    }
</style>