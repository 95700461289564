<!--  -->
<template>
    <div class='com-index-bcg'>
        <component-bcg url="index/bcg.png"></component-bcg>
        <div class="g-bcg" >
            <div class="item" v-for="(v, i) in bcgList" :key="i" :class="[v.class, v.extendClass]">
                <div class="item" v-for="(s, j) in v.children" :key="j" :class="s.class"></div>
            </div>
        </div>
        <div class="items-box">
            <slot name="items"></slot>
            <div class="item" v-for="(v, i) in itemsList" :key="`i-${i}`" :class="v.class" @click="funHand(v)">
                <div class="item" v-for="(s, j) in v.children" :key="`${i}-${j}`" :class="s.class"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {},
        data() {
            return {
                itemsList: [
                    {class: 'g56'},
                ],
                bcgList: [
                    {class: 'bcg_all'}
                    // {class: 'g1'},
                    // {class: 'g2'},
                    // {class: 'g3'},
                    // {class: 'g4'},
                    // {class: 'g5'},
                    // {class: 'g6'},
                    // {class: 'g7'},
                    // {class: 'g8'},
                    // {class: 'g9'},
                    // {class: 'g14'},
                    // {class: 'g19'},
                    // {class: 'g52'},
                    // {class: 'g20'},
                    // {class: 'g57'},
                    // {class: 'g58'},
                    // {class: 'g10'},
                    // {class: 'g11'},
                    // {class: 'g12'},
                    // {class: 'g13'},
                    // {class: 'g15'},
                    // {class: 'g16'},
                    // {class: 'g18'},
                    // {class: 'g17'},
                    // {class: 'g64'},
                    // {class: 'g21'},
                    // {class: 'g27'},
                    // {class: 'g22'},
                    // {class: 'g23'},
                    // {class: 'g26'},
                    // {class: 'g24'},
                    // {class: 'g25'},
                    // {class: 'g28'},
                    // {class: 'g29'},
                    // {class: 'g30'},
                    // {class: 'g31'},
                    // {class: 'g36'},
                    // {class: 'g32'},
                    // {class: 'g33'},
                    // {class: 'g34'},
                    // {class: 'g35'},
                    // {class: 'g37'},
                    // {class: 'g38'},
                    // {class: 'g39'},
                    // {class: 'g42'},
                    // {class: 'g40'},
                    // {class: 'g41'},
                    // {class: 'g62'},
                    // {class: 'g44'},
                    // {class: 'g43'},
                    // {class: 'g45'},
                    // {class: 'g50'},
                    // {class: 'g46'},
                    // {class: 'g65'},
                    // {class: 'g47'},
                    // {class: 'g48'},
                    // {class: 'g49'},
                    // {class: 'g51'},
                    // {class: 'g53'},
                    // {class: 'g54'},
                    // {class: 'g55'},
                    // {class: 'g59'},
                    // {class: 'g60'},
                    // {class: 'g61'},
                    // {class: 'g63'},
                ]
            };
        },
        methods: {

        },
        created() {

        },
        components: {
        },
        computed: {},
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-index-bcg {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        overflow: hidden;

        .items-box {
            z-index: 1;
        }

        .g-bcg,
        .items-box {
            position: absolute;
            width: 100%;
            height: 100%;
        }

        // .g1{
        //     .element-position(149px, 181px, 0px, 0px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g1.png'));
        // }
        // .g2{
        //     .element-position(104px, 168px, 117px, 12px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g2.png'));
        // }
        // .g3{
        //     .element-position(95px, 132px, 303px, 49px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g3.png'));
        // }
        // .g4{
        //     .element-position(122px, 134px, 401px, 47px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g4.png'));
        // }
        // .g5{
        //     .element-position(78px, 126px, 538px, 17px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g5.png'));
        // }
        // .g6{
        //     .element-position(439px, 223px, -160px, -47px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g6.png'));
        // }
        // .g7{
        //     .element-position(178px, 118px, 169px, 23px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g7.png'));
        // }
        // .g8{
        //     .element-position(291px, 125px, 457px, 36px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g8.png'));
        // }
        // .g9{
        //     .element-position(153px, 65px, 0px, 73px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g9.png'));
        // }
        // .g10{
        //     .element-position(75px, 50px, 0px, 95px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g10.png'));
        // }
        // .g11{
        //     .element-position(57px, 52px, 0px, 64px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g11.png'));
        // }
        // .g12{
        //     .element-position(88px, 55px, 123px, 104px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g12.png'));
        // }
        // .g13{
        //     .element-position(50px, 46px, 134px, 122px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g13.png'));
        // }
        // .g14{
        //     .element-position(242px, 70px, 157px, 74px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g14.png'));
        // }
        // .g15{
        //     .element-position(173px, 68px, 405px, 88px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g15.png'));
        // }
        // .g16{
        //     .element-position(50px, 46px, 515px, 124px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g16.png'));
        // }
        // .g17{
        //     .element-position(110px, 52px, 557px, 102px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g17.png'));
        // }
        // .g18{
        //     .element-position(107px, 111px, 560px, 122px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g18.png'));
        // }
        // .g19{
        //     .element-position(608px, 257px, 0px, 118px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g19.png'));
        // }
        // .g20{
        //     .element-position(172px, 244px, 0px, 131px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g20.png'));
        // }
        // .g21{
        //     .element-position(42px, 39px, 7px, 148px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g21.png'));
        // }
        // .g22{
        //     .element-position(50px, 46px, 30px, 223px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g22.png'));
        // }
        // .g23{
        //     .element-position(190px, 112px, 27px, 224px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g23.png'));
        // }
        // .g24{
        //     .element-position(50px, 46px, 112px, 300px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g24.png'));
        // }
        // .g25{
        //     .element-position(77px, 50px, 75px, 325px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g25.png'));
        // }
        // .g26{
        //     .element-position(94px, 48px, 144px, 308px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g26.png'));
        // }
        // .g27{
        //     .element-position(194px, 132px, 39px, 213px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g27.png'));
        // }
        // .g28{
        //     .element-position(280px, 198px, 170px, 177px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g28.png'));
        // }
        // .g29{
        //     .element-position(45px, 41px, 223px, 223px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g29.png'));
        // }
        // .g30{
        //     .element-position(186px, 99px, 220px, 234px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g30.png'));
        // }
        // .g31{
        //     .element-position(142px, 87px, 237px, 235px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g31.png'));
        // }
        // .g32{
        //     .element-position(123px, 67px, 223px, 224px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g32.png'));
        // }
        // .g33{
        //     .element-position(62px, 44px, 234px, 281px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g33.png'));
        // }
        // .g34{
        //     .element-position(156px, 103px, 227px, 226px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g34.png'));
        // }
        // .g35{
        //     .element-position(52px, 65px, 354px, 236px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g35.png'));
        // }
        // .g36{
        //     .element-position(27px, 42px, 248px, 215px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g36.png'));
        // }
        // .g37{
        //     .element-position(67px, 48px, 276px, 254px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g37.png'));
        // }
        // .g38{
        //     .element-position(203px, 38px, 169px, 337px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g38.png'));
        // }
        // .g39{
        //     .element-position(50px, 46px, 233px, 356px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g39.png'));
        // }
        // .g40{
        //     .element-position(143px, 28px, 286px, 347px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g40.png'));
        // }
        // .g41{
        //     .element-position(77px, 68px, 321px, 341px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g41.png'));
        // }
        // .g42{
        //     .element-position(193px, 80px, 357px, 295px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g42.png'));
        // }
        // .g43{
        //     .element-position(258px, 176px, 409px, 199px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g43.png'));
        // }
        // .g44{
        //     .element-position(254px, 154px, 321px, 109px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g44.png'));
        // }
        // .g45{
        //     .element-position(62px, 58px, 304px, 162px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g45.png'));
        // }
        // .g46{
        //     .element-position(130px, 78px, 537px, 156px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g46.png'));
        // }
        // .g47{
        //     .element-position(81px, 55px, 544px, 134px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g47.png'));
        // }
        // .g48{
        //     .element-position(31px, 20px, 595px, 168px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g48.png'));
        // }
        // .g49{
        //     .element-position(31px, 13px, 596px, 175px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g49.png'));
        // }
        // .g50{
        //     .element-position(173px, 70px, 494px, 203px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g50.png'));
        // }
        // .g51{
        //     .element-position(74px, 81px, 593px, 294px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g51.png'));
        // }
        // .g52{
        //     .element-position(323px, 220px, 62px, 123px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g52.png'));
        // }
        // .g53{
        //     .element-position(161px, 92px, 147px, 134px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g53.png'));
        // }
        // .g54{
        //     .element-position(91px, 70px, 145px, 142px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g54.png'));
        // }
        // .g55{
        //     .element-position(9px, 7px, 189px, 190px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g55.png'));
        // }

        // .g57{
        //     .element-position(141px, 205px, 9px, 170px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g57.png'));
        // }
        // .g58{
        //     .element-position(95px, 157px, 15px, 180px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g58.png'));
        // }
        // .g59{
        //     .element-position(122px, 30px, 493px, 239px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g59.png'));
        // }
        // .g60{
        //     .element-position(147px, 73px, 346px, 124px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g60.png'));
        // }
        // .g61{
        //     .element-position(110px, 44px, 387px, 154px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g61.png'));
        // }
        // .g62{
        //     .element-position(88px, 27px, 306px, 197px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g62.png'));
        // }
        // .g63{
        //     .element-position(56px, 25px, 109px, 240px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g63.png'));
        // }
        // .g64{
        //     .element-position(42px, 28px, 11px, 169px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g64.png'));
        // }
        // .g65{
        //     .element-position(71px, 34px, 565px, 158px);
        //     .background-img-max(url('@{assetsUrlV2_0}/index/g65.png'));
        // }




        .g66 {
            .element-position(157px, 154px, 149px, 56px);
            .background-img-max(url('@{assetsUrlV2_0}/index/g66.png'));
        }

        .g67 {
            .element-position(105px, 151px, 67px, 116px);
            .background-img-max(url('@{assetsUrlV2_0}/index/g67.png'));
        }

        .g68 {
            .element-position(146px, 137px, 344px, 60px);
            .background-img-max(url('@{assetsUrlV2_0}/index/g68.png'));
        }

        .g69 {
            .element-position(161px, 117px, 452px, 153px);
            .background-img-max(url('@{assetsUrlV2_0}/index/g69.png'));
        }

        .g56 {
            .element-position(50px, 46px, 194px, 182px);
            .background-img-max(url('@{assetsUrlV2_0}/index/g56.png'));
        }

        .bcg_all {
            .element-position(667px, 375px, 0px, 0px);
            .background-img-max(url('@{assetsUrlV2_0}/index/bcg_all.jpg'));
        }

        .index-module-item.disabled {
            filter: grayscale(1);
        }
    }
</style>