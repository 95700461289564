<template>
    <div class='page-index mind middle'>
        <div class="bcg-box">
            <bcg>
                <template v-slot:items>
                    <div class="index-module-item"
                        @mousedown="$event.currentTarget.classList.add(!v.disabled ? 'button-scale-down' : 'd')"
                        @touchstart="$event.currentTarget.classList.add(!v.disabled ? 'button-scale-down' : 'd')"
                        @touchleave="$event.currentTarget.classList.remove(!v.disabled ? 'button-scale-down' : 'd')"
                        @mouseleave="$event.currentTarget.classList.remove(!v.disabled ? 'button-scale-down' : 'd')"
                        @touchend="$event.currentTarget.classList.remove(!v.disabled ? 'button-scale-down' : 'd')"
                        @mouseup="$event.currentTarget.classList.remove(!v.disabled ? 'button-scale-down' : 'd')"
                        v-for="(v, i) in moduleList" :key="i" :class="[v.class, { 'disabled': v.disabled }]"
                        @click="middleGotoPage(v)">
                        <div class="title"></div>
                        <div class="m8-center-message" v-if="v.routeName == 'center' && newTask">
                            <div class="m8-center-title flex-center">
                                <div class="title-bgc"></div>
                            </div>
                            <!-- 旋转光 -->
                            <div class="m8-center-a1">
                                <div class="center-a1-light"></div>
                            </div>
                            <!-- new消息 -->
                            <div class="m8-center-a2 flex-center">
                                <div class="center-a2-bgc flex-center">
                                    <div ref="a2Ref1" class="a2-text">N</div>
                                    <div ref="a2Ref2" class="a2-text">e</div>
                                    <div ref="a2Ref3" class="a2-text">w</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </bcg>
        </div>
        <div class="user-box">
            <div class="user-box-fx">
                <userInfo></userInfo>
                <!-- <div class="mall-enter scale-button" v-show="!isMallOverdue" @click="_routeHand.goto({name: 'mall'})"></div> -->
                <!-- <trialCom ref="trialCom" v-show="showActivateFlag"></trialCom> -->
            </div>
        </div>
        <confirmPro ref="confirmPro" style="z-index: 300;">
            <div class="pro-close-box scale-button" slot="close" @click="quitApp"></div>
        </confirmPro>
        <weekSummary ref="weekSummary"></weekSummary>
    </div>
</template>
<script>
import com from '@/pages/mind/views/index/index.vue'
import bcg from './components/bcg.vue'
import TaskApi from '@/API/page/taskcenter.js'
import weekSummary from '@/pages/middle/components/weekSummary.vue'
export default {
    name: '',
    extends: com,
    props: {
    },
    data() {
        return {
            moduleList: [
                { class: 'm4', routeName: 'task' }, // 练功房
                { class: 'm5', routeName: 'cabin' }, // 名师讲堂
                { class: 'm6', routeName: 'palace', disabled: false }, // 知识海岛
                { class: 'm7', routeName: 'game', disabled: true }, // AI自习室
                { class: 'm8', routeName: 'center', disabled: false }, // 任务中心
                { class: 'm9', routeName: 'week', disabled: false }, // 周总结
            ],
            newTask: false,
            taskApi: new TaskApi(),
        };
    },
    methods: {
        mindPageInit() { },
        async userAccount() {
            let res = await this.api.userAccount()
            if (!res) return
        },
        middleGotoPage(val) {
            let { routeName, disabled } = val || {}
            if (routeName == 'center' && !disabled) return this._common.taskCenter()
            if (routeName == 'week' && !disabled) return this.$refs.weekSummary.open()
            this.gotoPage(val)
        },
        animation1() {
            const divA = this.$refs.a2Ref1 ? this.$refs.a2Ref1[0] : null;
            if (!divA) return
            divA.classList.remove('a2-1');
        },
        animation2() {
            const divB = this.$refs.a2Ref2 ? this.$refs.a2Ref2[0] : null;
            if (!divB) return
            divB.classList.remove('a2-2');
        },
        animation3() {
            const divA = this.$refs.a2Ref1 ? this.$refs.a2Ref1[0] : null;
            const divB = this.$refs.a2Ref2 ? this.$refs.a2Ref2[0] : null;
            const divC = this.$refs.a2Ref3 ? this.$refs.a2Ref3[0] : null;
            if (!divC || !divA || !divC) return
            divC.classList.remove('a2-3');
            setTimeout(() => {
                divA.classList.add('a2-1');
                divB.classList.add('a2-2');
                divC.classList.add('a2-3');
            }, 1000);
        },
        animationStart() {
            const divA = this.$refs.a2Ref1 ? this.$refs.a2Ref1[0] : null;
            const divB = this.$refs.a2Ref2 ? this.$refs.a2Ref2[0] : null;
            const divC = this.$refs.a2Ref3 ? this.$refs.a2Ref3[0] : null;
            if (!divA || !divB || !divC) return;
            divA.removeEventListener('animationend', this.animation1);
            divB.removeEventListener('animationend', this.animation2);
            divC.removeEventListener('animationend', this.animation3);
            divA.classList.add('a2-1');
            divB.classList.add('a2-2');
            divC.classList.add('a2-3');
            divA.addEventListener('animationend', this.animation1);
            divB.addEventListener('animationend', this.animation2);
            divC.addEventListener('animationend', this.animation3);
        },
        async haveNewTask() {
            const res = await this.taskApi.haveNewTask()
            if (!res) {
                this.newTask = false
                return false
            }
            this.newTask = true
            return true
        }
    },
    created() {
    },
    components: {
        bcg,
        weekSummary
    },
    computed: {
    },
    watch: {},
    mounted() {
        this.haveNewTask().then(() => {
            this.$nextTick(() => {
                this.animationStart()
            })
        })
    },
    beforeCreate() { }, //生命周期 - 创建之前
    beforeMount() { }, //生命周期 - 挂载之前
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="less">
.page-index.mind.middle {
    .user-box {
        padding-right: 0px;

        .user-box-fx {
            padding-right: 5px;
        }
    }

    .m8 {
        @keyframes m8rotate {
            from {
                transform: rotate(0deg);
            }

            to {
                transform: rotate(360deg);
            }
        }

        @keyframes a2wave {
            0% {
                transform: translateY(0);
            }

            25% {
                //   transform: translateY(-2px);
                transform: translateY(-10%);
            }

            50% {
                transform: translateY(0);
            }

            75% {
                //   transform: translateY(2px);
                transform: translateY(10%);
            }

            100% {
                transform: translateY(0);
            }
        }

        @keyframes a2wave2 {
            0% {
                transform: translateY(0);
            }

            25% {
                transform: translateY(2px);
            }

            50% {
                transform: translateY(0);
            }

            75% {
                transform: translateY(-2px);
            }

            100% {
                transform: translateY(0);
            }
        }

        .m8-center-message {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1;

            >.m8-center-title {
                position: absolute;
                width: 63px;
                height: 27px;
                left: 50%;
                top: 16px;
                transform: translateX(-50%);
                .background-img-max(url('@{assetsUrlMiddle}/img/taskCenter/home_center_s.png'));
                z-index: 2;

                >.title-bgc {
                    position: relative;
                    width: 56px;
                    height: 19px;
                    .background-img-max(url('@{assetsUrlMiddle}/img/taskCenter/home_center_bgc.png'));

                    &::before {
                        content: '';
                        position: absolute;
                        width: 51px;
                        height: 14px;
                        left: 50%;
                        top: 4px;
                        transform: translateX(-50%);
                        .background-img-max(url('@{assetsUrlMiddle}/img/home/b8_1.png'));
                    }
                }
            }

            >.m8-center-a1 {
                position: absolute;
                // top: -48px;
                top: -8px;
                left: 50%;
                z-index: 1;
                width: 78px;
                height: 77px;
                transform: translateX(-50%);

                >.center-a1-light {
                    //    width: 156px; 
                    //    height: 153px;
                    width: 100%;
                    height: 100%;
                    animation: m8rotate 4s linear infinite;
                    .background-img-max(url('@{assetsUrlMiddle}/img/taskCenter/home_light.png'));
                }
            }

            >.m8-center-a2 {
                position: absolute;
                right: 30px;
                top: 10px;
                z-index: 3;
                width: 33px;
                height: 20px;
                .background-img-max(url('@{assetsUrlMiddle}/img/taskCenter/new_s.png'));

                >.center-a2-bgc {
                    width: 25px;
                    height: 14px;
                    cursor: pointer;
                    .background-img-max(url('@{assetsUrlMiddle}/img/taskCenter/new_bgc.png'));

                    >.a2-text {
                        font-size: 7px;
                        font-weight: bold;
                        color: #ffffff;
                    }

                    >.a2-1 {
                        animation: a2wave 1s linear forwards;
                        animation-delay: 0s;
                    }

                    >.a2-2 {
                        animation: a2wave .9s linear forwards;
                        animation-delay: .2s;
                    }

                    .a2-3 {
                        animation: a2wave .8s linear forwards;
                        animation-delay: .4s;
                    }
                }
            }
        }
    }
}
</style>