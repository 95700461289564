<!--  -->
<template>
    <div class='com-user-info' @click="$emit('click')">
        <!-- @click="popOpen" -->
        <div class="info-item block cursor-pointer">
            <user></user>
        </div>
        <div class="info-item popup">
            <van-popup v-model="popupActive" position="left" :style="{ height: '100%', width: '30%' }">
                <div class="user-info-detail light-dark-color">
                    <user></user>
                    <div class="foot">
                        <van-button round type="danger" block @click="quit">退出登录</van-button>
                    </div>
                </div>
            </van-popup>
        </div>
    </div>
</template>

<script>
    import api from '@/API/page/app'

    export default {
        name: '',
        props: {},
        data() {
            return {
                api: new api(),
                popupActive: false,
                userDetail: {}
            };
        },
        methods: {
            popOpen(){
                if(!this._userHand.loginStatus) return this.goto({name: 'login', type: 'replace'})
                this.popupActive = true
            },
            popClose(){
                this.popupActive = false
            },
            async quit(){
                let res = await this.quitConfirm()
                if(!res) return
                await this.quitAction()
            },
            async quitConfirm(){
                let res = await this._confirm.open({
                    message: this.TEXT.components.t1
                })
                return res
            },
            quitAction(){
                this._userHand.userLogoutSet()
                this.goto({name: 'login', type: 'replace'})
            },
            async userInfoInit(){
                if(!this._userHand.loginStatus) return
                let res = await this.api.userLevel()
                if(res) this.userDetail = res
            },
            goto(opt){
                this._routeHand.goto(opt)
            }
        },
        created() {
            this.userInfoInit()
        },
        components: {
        },
        computed: {},
        watch: {
        },
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-user-info{
        .info-item.block{
            // display: flex;
            // align-items: center;
            // .user-photo{
            //     .user-photo-img{
            //         width: 20px;
            //         border-radius: 100%;
            //         float: left;
            //     }
            // }
            // .user-name{
            //     margin-left: 5px;
            // }
        }
        .info-item.popup{
            .user-info-detail{
                position: relative;
                padding: 10px;
                height: 100%;
                .com-item-user{
                    .user-photo{
                        .user-photo-img{
                            width: 40px;
                        }
                    }
                    .user-name{
                        > span{
                            font-size: 14px;
                        }
                    }

                }
                .foot{
                    padding: 10px;
                    position: absolute;
                    bottom: 0px;
                    left: 0px;
                    width: 100%;
                }
            }
        }
    }
</style>