<!--  -->
<template>
    <div class='com-index-animation'>

    </div>
</template>

<script>
    export default {
        name: '',
        props: {},
        data() {
            return {

            };
        },
        methods: {

        },
        created() {

        },
        components: {
        },
        computed: {},
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-index-animation{
        position: absolute;
        width: 69px;
        height: 45px;
        right: 0px;
        top: 6px;
        z-index: 2;
        animation: cloud-move 30s linear infinite;
        .background-img-max(url('@{assetsUrlV2_0}/index/g70.png'));
    }
</style>