<!--  -->
<template>
    <div class='com-user-info' @click="$emit('click')">
        <!-- @click="popOpen" -->
        <div class="info-item block cursor-pointer">
            <user></user>
        </div>
        <div class="info-item popup">
            <van-popup v-model="popupActive" position="left" :style="{ height: '100%', width: '30%' }">
                <div class="user-info-detail light-dark-color">
                    <user></user>
                    <div class="foot">
                        <van-button round type="danger" block @click="quit">退出登录</van-button>
                    </div>
                </div>
            </van-popup>
        </div>
    </div>
</template>

<script>
    import com from '@/components/userInfo.vue'

    export default {
        name: '',
        props: {},
        extends: com,
        data() {
            return {
            };
        },
        methods: {
            async userInfoInit(){},
        },
        created() {
        },
        components: {
        },
        computed: {},
        watch: {
        },
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
</style>